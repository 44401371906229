import React from "react"
import styled from "@emotion/styled"


const ScheduleCont = styled.div`
    display: flex;
    align-content: start;
    justify-content: center;
    flex-wrap: wrap;
    perspective: 1000px;
    height: 2000px;
    padding: 0px 20px;
`

const ScheduleContainer = ({children}) => (
    <ScheduleCont className={`sCont`}>
        {children}
    </ScheduleCont>
)

export default ScheduleContainer;