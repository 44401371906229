import { Link } from "gatsby";
import React from "react";
import styled from "@emotion/styled";

const TopnavMaincont = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #1c1e1f;
  justify-content: space-between;
`;

const TNavLinkToHome = styled.div`
  font-size: 28px;
  font-weight: bold;
  height: 100%;
  position: relative;
  transform: translateY(25%);
  margin-left: 10px;
`;

const TopnavRightSide = styled.div`
  margin-right: 10px;
  font-size: 18px;
  transform: translateY(35%);
`;

const StyledLink = styled(props => <Link {...props} />)`
  color: #F8F7FF;
  margin-right: 10px;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
    text-decoration-color: var(--terminalGreen);
  }
`;

const SLink = styled.a`
  color: #F8F7FF;
  margin-right: 10px;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
    text-decoration-color: var(--terminalGreen);
  }
`;

const ExternalLink = styled.a`
  color: #F8F7FF;
  text-decoration: none;
  top:50%;
  margin-right: 10px;
  &:hover, &:focus {
    text-decoration: underline;
    text-decoration-color: var(--terminalGreen);
  }
`;


const Topnav = () => (
  <TopnavMaincont>
    <TNavLinkToHome>
      <StyledLink to={`/`}>Home</StyledLink>
    </TNavLinkToHome>
    <TopnavRightSide>
      <ExternalLink href={`https://allstarcode.org`}>> All Star Code </ExternalLink>
      <SLink href={`/appendix`}>> Appendix</SLink>
    </TopnavRightSide>
  </TopnavMaincont>
)


export default Topnav;
