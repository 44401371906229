import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import AscGlitchStar from "./glitch_star"


const Maincontainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #212121;
    text-align: center;
    align-items: center;
`
const Underlined = styled.span`
    text-decoration: underline;
`

//🡸 🡺

const DropdownItem = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    & > * {
        transition: all .5s ease-in-out;
    }
    &:hover > * {
        height: 20px;
        visibility: visible;
    }
`

const DropdownSubItem = styled.div`
    visibility: hidden;
    height: 0px;
    font-size: 18px;
    text-decoration: none;
    margin-top: 5px;
    &:hover {
        transform: scale(1.5);
    }
`

const LNavStar = styled.div`
    position: relative;
    height: 150px;
    width: 150px;
    margin-top: -75px;
`

const StyledLink = styled.a`
    color: #F8F7FF;
    text-decoration: none;
    top: 0;
    font-family: 'Inconsolata', monospace;
    &:hover ::after{
        visibility: visible;
    }
    &:hover ::before{
        visibility: visible;
    }
    &::after, &::before {
        width: 100%;
        height: 100%;
        color: #51C1E9;
        animation: CoolFlicker 1.55s infinite linear;
        font-size: 12px;
        visibility: hidden;
    }
    &::after {
        content: ' 🡸';
    }
    &::before {
        content: '🡺 ';
    }
`

const Leftnav = () => (
    <Maincontainer>
            <LNavStar>
                <Link to={`/`}> <AscGlitchStar /> </Link>
            </LNavStar>

        <DropdownItem><StyledLink href={`/week1`}><Underlined>Week 01</Underlined></StyledLink>
            <DropdownSubItem ><StyledLink href={`/week1/day1`}>Day 01</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week1/day2`}>Day 02</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week1/day3`}>Day 03</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week1/day4`}>Day 04</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week1/day5`}>Day 05</StyledLink></DropdownSubItem>
        </DropdownItem>
        <DropdownItem><StyledLink href={`/week2`}><Underlined>Week 02</Underlined></StyledLink>
            <DropdownSubItem ><StyledLink href={`/week2/day1`}>Day 01</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week2/day2`}>Day 02</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week2/day3`}>Day 03</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week2/day4`}>Day 04</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week2/day5`}>Day 05</StyledLink></DropdownSubItem>
        </DropdownItem>
        <DropdownItem><StyledLink href={`/week3`}><Underlined>Week 03</Underlined></StyledLink>
            <DropdownSubItem ><StyledLink href={`/week3/day1`}>Day 01</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week3/day2`}>Day 02</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week3/day3`}>Day 03</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week3/day4`}>Day 04</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week3/day5`}>Day 05</StyledLink></DropdownSubItem>
        </DropdownItem>
        <DropdownItem><StyledLink href={`/week4`}><Underlined>Week 04</Underlined></StyledLink>
            <DropdownSubItem ><StyledLink href={`/week4/day1`}>Day 01</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week4/day2`}>Day 02</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week4/day3`}>Day 03</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week4/day4`}>Day 04</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week4/day5`}>Day 05</StyledLink></DropdownSubItem>
        </DropdownItem>
        <DropdownItem><StyledLink href={`/week5`}><Underlined>Week 05</Underlined></StyledLink>
            <DropdownSubItem ><StyledLink href={`/week5/day1`}>Day 01</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week5/day2`}>Day 02</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week5/day3`}>Day 03</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week5/day4`}>Day 04</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week5/day5`}>Day 05</StyledLink></DropdownSubItem>
        </DropdownItem>
        <DropdownItem><StyledLink href={`/week6`}><Underlined>Week 06</Underlined></StyledLink>
            <DropdownSubItem ><StyledLink href={`/week6/day1`}>Day 01</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week6/day2`}>Day 02</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week6/day3`}>Day 03</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week6/day4`}>Day 04</StyledLink></DropdownSubItem>
            <DropdownSubItem ><StyledLink href={`/week6/day5`}>Day 05</StyledLink></DropdownSubItem>
        </DropdownItem>
    </Maincontainer>
)

export default Leftnav;