import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import Helmet from "react-helmet"

const TurnOn = keyframes`
0% {
    transform: scale(1, .0001) translate3d(0, 0, 0);
    filter: brightness(30);
    opacity: 1;
}
50% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0);
    opacity: 0;
}
100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
}
`

const Wrapper = styled.div`
  max-height: 92vh;
  height: 100%;
  width: 100%;
  top: 0;
  position: relative;
  background-image: radial-gradient(rgba(0, 18, 5, 0.75), black 130%);
  transform: scale(0, 0.0001) translate3d(0, 0, 0);
  filter: brightness(50);
  font-family: "Inconsolata", monospace;
  text-align: center;
  animation: ${TurnOn} 1.5s ease-in-out;
  animation-fill-mode: forwards;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Scanline = keyframes`
    from {
        top: 0px;
    } to {
        top: 100%;
    }
`

const Vertline = styled.div`
  position: absolute;
  height: 7px;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba(38, 38, 38, 0.2);
  z-index: 1;
  animation: ${Scanline} 7.5s linear infinite;
`

const DayScheduleWrapper = ({ children }) => (
  <Wrapper>
    <Helmet>
      <script
        src="https://cdn.jsdelivr.net/gh/DanteASC4/external_scripts@1.2/2020_curriculum_site/highlight.js"
        type="text/javascript"
        defer
      />
    </Helmet>

    <Vertline></Vertline>
    {children}
  </Wrapper>
)

export default DayScheduleWrapper
