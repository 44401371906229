import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AscGlitchStar = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "asc_glitch_no_bg_or_scanlines.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img akt="ASC Asterik Logo" fixed={data.placeholderImage.childImageSharp.fixed} />
}

export default AscGlitchStar
