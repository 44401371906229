import React from "react";
import styled from "@emotion/styled";


let NavT = styled.div`
    position: absolute;
    right:0;
    height: auto;
    width: 0px;
    top: 0;
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
    background-color: #1c1e1f;
    border-left: 1px solid rgba(102, 102, 102, 1);
    grid-template-rows: 5% 19% 19% 19% 19% 19%;
    display: grid;
    grid-template-rows: 50px 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    & > * {
        font-family: 'Inconsolata', monospace;
    }
    & div {
        border-bottom: 1px solid rgba(102, 102, 102, 1);
    }
    & div:last-child {
        border: none;
    }
`;

let DayBox = styled.div`
    height: auto;
    width: 100%;
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 10px;
    &:hover > div > a{
        opacity: 1;
        visibility: visible;
        height: 100%;
    }
`;

const SLink = styled.a`
    color: white;
    font-size: 24px;
    font-weight: bold;
    top: 0;
    text-align: center;
    font-family: 'Inconsolata', monospace;
    animation: CoolFlicker 1.55s infinite linear;
`;

const NavSubTitle = styled.span`
    text-align: center;
    font-size: 28px;
    margin-bottom: 15px;
    font-weight: bold;
    height: auto;
    width: 100%;
    color: #51c1e9;
    animation: CoolFlicker 1.55s infinite linear;
`;

const LinkBox = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    text-align:center;
    min-height: 75px;
    & > a {
        opacity: 0;
        height: 0px;
        transition: all .5s ease-in-out;
        visibility: hidden;
    }
`;

// const ExternalLink = styled.a`

// `


const NavBox = ({children, ...props}) => (
    <NavT id={`rnav`}>
        {children}
    </NavT>
)

const NavTitle = props => (
    <NavSubTitle>Quick Nav</NavSubTitle>
)

const Day = ({children, ...props}) => (
    <DayBox>
        {children}
    </DayBox>
)

const StyledLink = props => (
    <SLink href={props.location}>{props.name}</SLink>
)

const TopicBox = ({children, ...props}) => (
    <LinkBox>
        {children}
    </LinkBox>
)

const ExternalLink = props => (
    <a css={{color: (props.type === 'project' ? '#dd404d' : props.type === 'lecture' ? '#A285DC' : '#FFF' ) }} href={props.link} target="_blank" rel="noopener noreferrer" rek="noopener noreferrer">{props.name}</a>
)

export { NavBox, NavTitle, Day, StyledLink, TopicBox, ExternalLink }