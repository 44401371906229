import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";

const DayTitle = styled.div`
    display: inline-block;
    font-size: 48px;
    font-weight: bold;
    font-family: 'Inconsolata', monospace;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    color: #23fcc7;
    filter: blur(0.03em);
    &::after {
        content: attr(data-day-num);
        color: var(--terminalGreen);
        font-size: 500px;
        position: absolute;
        top: -40px;
        left: 75px;
        margin-right: 50%;
        transform: scale(1.4);
        opacity: 0.05;
    }
`;

const DayTitleText = styled.span`
    border-right: 10px solid #23fcc7;
    padding-right: 5px;
    animation: CursorBlink 1s step-end infinite, CoolFlicker 2.6s infinite linear;
`;

const DayDate = styled.div`
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const DayTSubhead = styled.div`
    width: 100%;
    animation: CoolFlicker 1.5s infinite linear;
    display: flex;
    justify-content: space-around;
    align-content: center;
    color: #23fcc7;
    margin: 35px 0px;
`;

// const StyledLink = styled(props => <Link {...props} />)`
//     color: #23fcc7;
//     z-index: 1;
// `

const StyledLink = styled.a`
    color: #23fcc7;
    z-index: 1;
`

const SwitchB = styled.button`
    height: 35px;
    width: 100px;
    border: 1px solid #23fcc7;
    background-color: transparent;
    color: #23fcc7;
    z-index: 1000;
    padding: 0px;
`;


/*
            (<>
            <StyledLink href={`/week${props.weeknum}/`}>🡸 Week {props.weeknum} </StyledLink>
            <DayDate id={`dayDate`}>{props.dateText}</DayDate>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>)

            (<>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
            <DayDate id={`dayDate`}>{props.dateText}</DayDate>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>)

            (<>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
            <DayDate id={`dayDate`}>{props.dateText}</DayDate>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>)

            (<>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
            <DayDate id={`dayDate`}>{props.dateText}</DayDate>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>)

            (<>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
            <DayDate id={`dayDate`}>{props.dateText}</DayDate>
            <StyledLink href={`/week${props.weeknum + 1}/`}>Week 2🡺 </StyledLink>
            </>)
*/


const DayTitleArea = props => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.tabtitle}</title>
        </Helmet>
        <DayTitle data-day-num={props.daynum}>
            <DayTitleText>{props.titleText}</DayTitleText>
        </DayTitle>
        <DayTSubhead>
        {
        (props.dayofweek === '1') ?
            (<>
            <StyledLink href={`/week${props.weeknum}/`}>🡸 Week {props.weeknum} </StyledLink>
            <DayDate id={`dayDate`}>
                {props.dateText}
                <SwitchB className={`tooltip`}>
                    <span id={`cooldown-text`}></span>
                    <span id={`button-text`}>Morning Schedule</span>
                    <span className={`tooltip-text`}>Click to swap between morning and afternoon schedule.</span>
                </SwitchB>
            </DayDate>
            <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>) :
        (props.dayofweek === '2') ?
            (<>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
                <DayDate id={`dayDate`}>
                    {props.dateText}
                    <SwitchB className={`tooltip`}>
                        <span id={`cooldown-text`}></span>
                        <span id={`button-text`}>Morning Schedule</span>
                        <span className={`tooltip-text`}>Click to swap between morning and afternoon schedule.</span>
                    </SwitchB>
                </DayDate>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>) :
        (props.dayofweek === '3') ?
            (<>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
                <DayDate id={`dayDate`}>
                    {props.dateText}
                    <SwitchB className={`tooltip`}>
                        <span id={`cooldown-text`}></span>
                        <span id={`button-text`}>Morning Schedule</span>
                        <span className={`tooltip-text`}>Click to swap between morning and afternoon schedule.</span>
                    </SwitchB>
                </DayDate>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>) :
        (props.dayofweek === '4') ?
            (<>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
                <DayDate id={`dayDate`}>
                    {props.dateText}
                    <SwitchB className={`tooltip`}>
                        <span id={`cooldown-text`}></span>
                        <span id={`button-text`}>Morning Schedule</span>
                        <span className={`tooltip-text`}>Click to swap between morning and afternoon schedule.</span>
                    </SwitchB>
                </DayDate>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) + 1}/`}>Day {parseInt(props.dayofweek) + 1} 🡺 </StyledLink>
            </>) :
        (props.dayofweek === '5') ?
            (<>
                <StyledLink href={`/week${props.weeknum}/day${parseInt(props.dayofweek) - 1}/`}>🡸 Day {parseInt(props.dayofweek) - 1} </StyledLink>
                <DayDate id={`dayDate`}>
                    {props.dateText}
                    <SwitchB className={`tooltip`}>
                        <span id={`cooldown-text`}></span>
                        <span id={`button-text`}>Morning Schedule</span>
                        <span className={`tooltip-text`}>Click to swap between morning and afternoon schedule.</span>
                    </SwitchB>
                </DayDate>
                <StyledLink href={`/week${props.weeknum + 1}/`}>Week 2🡺 </StyledLink>
            </>) :
        (<>
            <DayDate id={`dayDate`}>
                {props.dateText}
            </DayDate>
        </>)
        }
        </DayTSubhead>
    </>
)

export default DayTitleArea;