import React from "react"
import styled from "@emotion/styled"

const ProjectBox = styled.div`
  perspective: 1000px;
  min-width: 100%;
  color: #dd404d;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  text-align: center;
  filter: brightness(1.3);
  &::before {
    z-index: -1;
    opacity: 0.6;
    background: linear-gradient(
      0deg,
      rgba(190, 16, 0, 1) 0%,
      rgba(25, 27, 28, 1) 15%,
      rgba(25, 27, 28, 1) 50%,
      rgba(25, 27, 28, 1) 85%,
      rgba(190, 16, 0, 1) 100%
    );
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
  }
  /* &::after {
        z-index: -1;
        opacity: 0.60;
        background:  linear-gradient(90deg, rgba(190, 16, 0, 1) 0%, rgba(25,27,28,1) 15%, rgba(25,27,28,1) 50%, rgba(25,27,28,1) 85%, rgba(190, 16, 0, 1) 100%);
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
    } */
`;

const LectureBox = styled.div`
  perspective: 1000px;
  min-width: 100%;
  color: #a285dc;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  text-align: center;
  filter: brightness(1.3);
  &::before {
    z-index: -1;
    opacity: 0.6;
    background: linear-gradient(
      0deg,
      rgba(124, 95, 182, 1) 0%,
      rgba(25, 27, 28, 1) 15%,
      rgba(25, 27, 28, 1) 50%,
      rgba(25, 27, 28, 1) 85%,
      rgba(124, 95, 182, 1) 100%
    );
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const SurveyBox = styled.div`
  perspective: 1000px;
  min-width: 100%;
  color: #1dd2a5;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  text-align: center;
  filter: brightness(1.3);
  &::before {
    z-index: -1;
    opacity: 0.6;
    background: linear-gradient(
      0deg,
      rgba(35, 252, 199, 1) 0%,
      rgba(25, 27, 28, 1) 15%,
      rgba(25, 27, 28, 1) 50%,
      rgba(25, 27, 28, 1) 85%,
      rgba(35, 252, 199, 1) 100%
    );
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const MiscBox = styled.div`
  perspective: 1000px;
  min-width: 100%;
  color: #a3a3a3;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  text-align: center;
  filter: brightness(1.3);
  &::before {
    z-index: -1;
    opacity: 0.6;
    background: linear-gradient(
      0deg,
      rgba(163, 163, 163, 1) 0%,
      rgba(25, 27, 28, 1) 15%,
      rgba(25, 27, 28, 1) 50%,
      rgba(25, 27, 28, 1) 85%,
      rgba(163, 163, 163, 1) 100%
    );
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const CoreBox = styled.div`
  perspective: 1000px;
  min-width: 100%;
  color: #51c1e9;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  text-align: center;
  filter: brightness(1.3);
  &::before {
    z-index: -1;
    opacity: 0.6;
    background: linear-gradient(
      0deg,
      rgba(81, 192, 233, 1) 0%,
      rgba(25, 27, 28, 1) 15%,
      rgba(25, 27, 28, 1) 50%,
      rgba(25, 27, 28, 1) 85%,
      rgba(81, 192, 233, 1) 100%
    );
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const ReviewBox = styled.div`
  perspective: 1000px;
  min-width: 100%;
  color: #c8901b;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  text-align: center;
  filter: brightness(1.3);
  &::before {
    z-index: -1;
    opacity: 0.6;
    background: linear-gradient(
      0deg,
      rgba(200, 144, 27, 1) 0%,
      rgba(25, 27, 28, 1) 15%,
      rgba(25, 27, 28, 1) 50%,
      rgba(25, 27, 28, 1) 85%,
      rgba(200, 144, 27, 1) 100%
    );
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const Description = styled.div`
  min-width: 500px;
  max-width: 500px;
  padding: 10px 0px;
  margin-right: 25px;
  font-size: 15px;
  align-self: center;
  & > p {
    width: 100%;
    height: 100%;
  }
  text-align: left;
`;

const Name = styled.span`
  width: 90%;
  text-align: center;
  padding: 10px 0px;
  align-self: center;
  & > a {
    color: inherit;
  }
`;

const Time = styled.div`
  align-self: center;
`;

const Resources = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  & > a {
    display: inline-block;
    min-width: 100%;
    color: #51c1e9;
  }
`;

const Project = props => (
  <ProjectBox data-version={props.version} className={`activity`}>
    <Name>
      {props.activitymateriallink ? (
        <a
          href={props.activitymateriallink}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityname}
        </a>
      ) : (
        <p>{props.activityname}</p>
      )}
    </Name>
    <Time className={`time`}>{props.activitytime}</Time>
    <Resources>
      {props.activityres1link !== "" ? (
        <a
          href={props.activityres1link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres1name}
        </a>
      ) : null}
      {props.activityres2link !== "" ? (
        <a
          href={props.activityres2link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres2name}
        </a>
      ) : null}
    </Resources>
    <Description>
      <p>{props.activitydesc}</p>
    </Description>
  </ProjectBox>
)

// Lectures now encompass codealongs
const Lecture = props => (
  <LectureBox data-version={props.version} className={`activity`}>
    <Name>
      {props.activitymateriallink ? (
        <a
          href={props.activitymateriallink}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityname}
        </a>
      ) : (
        <p>{props.activityname}</p>
      )}
    </Name>
    <Time className={`time`}>{props.activitytime}</Time>
    <Resources>
      {props.activityres1link !== "" ? (
        <a
          href={props.activityres1link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres1name}
        </a>
      ) : null}
      {props.activityres2link !== "" ? (
        <a
          href={props.activityres2link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres2name}
        </a>
      ) : null}
    </Resources>
    <Description>
      <p>{props.activitydesc}</p>
    </Description>
  </LectureBox>
)

const Survey = props => (
  <SurveyBox data-version={props.version} className={`activity`}>
    <Name>
      {props.activitymateriallink ? (
        <a
          href={props.activitymateriallink}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityname}
        </a>
      ) : (
        <p>{props.activityname}</p>
      )}
    </Name>
    <Time className={`time`}>{props.activitytime}</Time>
    <Resources>
      {props.activityres1link !== "" ? (
        <a
          href={props.activityres1link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres1name}
        </a>
      ) : null}
      {props.activityres2link !== "" ? (
        <a
          href={props.activityres2link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres2name}
        </a>
      ) : null}
    </Resources>
    <Description>
      <p>{props.activitydesc}</p>
    </Description>
  </SurveyBox>
)

const Misc = props => (
  <MiscBox data-version={props.version} className={`activity`}>
    <Name>
      {props.activitymateriallink ? (
        <a
          href={props.activitymateriallink}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityname}
        </a>
      ) : (
        <p>{props.activityname}</p>
      )}
    </Name>
    <Time className={`time`}>{props.activitytime}</Time>
    <Resources>
      {props.activityres1link !== "" ? (
        <a
          href={props.activityres1link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres1name}
        </a>
      ) : null}
      {props.activityres2link !== "" ? (
        <a
          href={props.activityres2link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres2name}
        </a>
      ) : null}
    </Resources>
    <Description>
      <p>{props.activitydesc}</p>
    </Description>
  </MiscBox>
)

const Coreskill = props => (
  <CoreBox data-version={props.version} className={`activity`}>
    <Name>
      {props.activitymateriallink ? (
        <a
          href={props.activitymateriallink}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityname}
        </a>
      ) : (
        <p>{props.activityname}</p>
      )}
    </Name>
    <Time className={`time`}>{props.activitytime}</Time>
    <Resources>
      {props.activityres1link !== "" ? (
        <a
          href={props.activityres1link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres1name}
        </a>
      ) : null}
      {props.activityres2link !== "" ? (
        <a
          href={props.activityres2link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres2name}
        </a>
      ) : null}
    </Resources>
    <Description>
      <p>{props.activitydesc}</p>
    </Description>
  </CoreBox>
)

const Review = props => (
  <ReviewBox data-version={props.version} className={`activity`}>
    <Name>
      {props.activitymateriallink ? (
        <a
          href={props.activitymateriallink}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityname}
        </a>
      ) : (
        <p>{props.activityname}</p>
      )}
    </Name>
    <Time className={`time`}>{props.activitytime}</Time>
    <Resources>
      {props.activityres1link !== "" ? (
        <a
          href={props.activityres1link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres1name}
        </a>
      ) : null}
      {props.activityres2link !== "" ? (
        <a
          href={props.activityres2link}
          target="_blank"
          rel="noopener noreferrer"
          rek="noopener noreferrer"
        >
          {props.activityres2name}
        </a>
      ) : null}
    </Resources>
    <Description>
      <p>{props.activitydesc}</p>
    </Description>
  </ReviewBox>
)

export { Project, Lecture, Misc, Coreskill, Review, Survey }
