import React from "react"
import styled from "@emotion/styled"

const ArrowBox = styled.div`
    height: 100%;
    position: fixed;
    font-size: 100px;
    font-weight: bold;
    margin-right: 5px;
    color: white;
    z-index: 1001;
    right: 0;
    top: 0;
    left: 95%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #23fcc7;
    animation: CoolFlicker 1.5s infinite linear;
    & > div:hover {
        cursor: pointer;
    }
`
// 🐱‍👤🐱‍👤🐱‍👤
const Dim = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: -1000;
  background-color: rgba(18, 18, 18, 0.75);
`
// TODO Fix the dim element so the arrow doesn't get dimmed by it.

const Arrow = () => (
    <>
        <div>
            <Dim id={`dim`}></Dim>
        </div>
        <ArrowBox id={`arrow`} data-state={`closed`}>
            <div id={`arrowT`}> ❮ </div>
        </ArrowBox>
    </>
)

export default Arrow;