import React from "react";
import styled from "@emotion/styled";

import Topnav from "./topnavbar";
import Leftnav from "./leftnavbar";
import Helmet from "react-helmet";
import Arrow from "./arrow";
import { MDXProvider } from "@mdx-js/react";

import "./layout.css";
// import { withPrefix } from "gatsby"

import DayScheduleWrapper from "./schedule_components/wrapper";
import DayTitleArea from "./schedule_components/daytitle";
import ScheduleContainer from "./schedule_components/schedulecontainer";
import { Project, Lecture, Misc, Coreskill, Review, Survey } from "./schedule_components/allactivitytypes";


const shortcodes = { Arrow, DayScheduleWrapper, DayTitleArea, ScheduleContainer, Project, Lecture, Misc, Coreskill, Review, Survey };
/**
 * Layout items explained
 *
 * Leftnav:
 * The week list, with titles of each week
 *
 * Topnav:
 * The 'normal' navbar, with ALL STAR CODE which links to main site (allstarcode.org) and anything else like announcements, resources, etc...
 *
 * Rightnav:
 * The right side navbar will have an overview of each day while on week view and day view, might just collapse it on main page, or have some
 * quotes / random things there.
 *
 * Sitecont:
 * The actual content of the site, see the corresponding area in wireframe for more detailed explanation as it's easier to see than explain
 */




const Container = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr;
  grid-template-rows: 75px 1fr;
  grid-template-areas:
    "Leftnav Topnav  "
    "Leftnav Sitecont"
    "Leftnav Sitecont";
  height: 100vh;
  width: 100vw;
    &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
  }
  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 10000;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
`;



const Leftnavbar = styled.div`
  grid-area: Leftnav;
  border-right: 1px solid #737474 ;
`;

const Topnavbar = styled.div`
  grid-area: Topnav;
  border-bottom: 1px solid #737474;
  z-index: 1002;
`;

const Rightnavbar = styled.div``;

const brL = str => {
  console.log(`%c${str}`, 'font-weight: bold; font-size: 50px;color: #00FF00; ');
};

brL('Sup');





const Sitecontent = styled.div`
  grid-area: Sitecont;
`;

const Layout = ({ children, ...props }) => (
  <>
    <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/animejs@3.1.0/lib/anime.min.js"></script>
        {/* <script src={withPrefix('animae.js')} type="text/javascript" defer /> */}
    </Helmet>
    <Container id={`bigCont`}>
      <Leftnavbar id="mainLeftNav">
        {/* Left Nav component will go here */}
        <Leftnav></Leftnav>
      </Leftnavbar>
      <Topnavbar id="mainTopNav">
        {/* <OnOffButton className={`onoff`}></OnOffButton> */}
        {/* Middlenav, might change the header ele to middle nav */}
        <Topnav />
      </Topnavbar>
      <Rightnavbar id="mainRightNav">
        {props.rightnav}
      </Rightnavbar>
      <Sitecontent>
        <MDXProvider components={shortcodes}>
        {/* site content  */}
          {children}
        </MDXProvider>
      </Sitecontent>
    </Container>
  </>
)


export default Layout;